import './footer.css';

function Footer() {
    return (
        <>
            <footer>
                <h1>© 2024 Fernanda Nóbrega e Ariel Freitas. Todos os direitos reservados.</h1>
            </footer>
        </>
    );
}

export default Footer;
